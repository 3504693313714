<template>
  <div class="login">
    <div class="login-cover">
      <!-- <img src="@/assets/images/bg_web.png"> -->
    </div>
    <div class="login-right">
      <div class="logo">
        <icon icon="icon-logo" class="logos" />
      </div>
      <div class="login-hello">欢迎来到玖拾捌度后台管理！</div>
      <div class="login-box">
        <div class="switch-type" :class="{ 'account': active === 0 }" @click="active = ~~!Boolean(active)"></div>
        <div class="login-tab">
          <div class="tab-head">
            <div class="tab-title">
              <span>{{ ['扫码登录', '手机号登录'][active] }}</span>
              <!-- <p>扫码登录</p> -->
              <!-- <p>请使用钉钉扫描二维码登录</p> -->
            </div>
            <div class="tab-title" :class="{ 'on': active === 1 }" @click="active = 1">
              <span></span>
            </div>
          </div>
          <div class="ding-ding" v-show="active === 0">
            <div class="ding-qrcode">
              <div id="DingLogin"></div>
            </div>
          </div>
          <el-form ref="form" :model="form" :rules="rules" v-show="active === 1">
            <el-form-item prop="mobile">
              <el-input
                v-model="form.mobile"
                placeholder="请输入手机号"
                name="mobile"
                type="text"
                auto-complete="on"
              />
            </el-form-item>

            <el-form-item prop="password">
              <el-input
                v-model="form.password"
                type="password"
                placeholder="请输入密码"
                name="password"
                auto-complete="on"
              />
            </el-form-item>
            <!-- <el-form-item prop="captcha" class="code-img-item">
              <el-input
                class="captcha"
                v-model="form.captcha"
                placeholder="请输入验证码"
                name="captcha"
                type="text"
                auto-complete="on"
              />
              <div class="code-img">
                <span v-show="!captchaImg">获取验证码</span>
                <img :src="captchaImg" @click="changeCaptchas" v-show="captchaImg" />
              </div>
            </el-form-item> -->
            <div class="form-item">
              <el-button
                type="primary"
                native-type="submit"
                @click.native.prevent="handleLogin"
              >立即登录</el-button>
              <!-- <el-button
                type="info"
                @click.native.prevent="handleForget"
              >忘记密码</el-button> -->
            </div>
            <div class="forget">
              <span @click="handleForget">忘记密码？</span>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { getCaptcha } from '@/api/login'
// import qs from 'qs'
import Cookies from 'js-cookie'
// import { param } from '@/utils'
import { expires } from '@/var/config'
import { getUrlWithParams } from '../redirect/redirect'

const APPID = 'dingoatdbdrikxfahvy8ae' // 钉钉APPID
// 注意: 这里钉钉的重定向地址只有localdev的.
const REDIRECT_URI = encodeURIComponent(`http://localdev.auth.98du.com/redirect`) // 重定向地址
const HTTP_URL = encodeURIComponent(`https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${APPID}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${REDIRECT_URI}`)

export default {
  name: 'Login',
  data() {
    return {
      active: this.$route.query.mobile ? 1 : 0,
      form: {
        // encKey: '',
        mobile: '',
        password: '',
        // captcha: ''
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { pattern: /^0{0,1}(13[0-9]|15[0-9]|153|156|18[7-9])[0-9]{8}$/, message: '请输入手机号码', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入登录密码', trigger: 'blur' }],
        captcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      captchaImg: undefined,
    }
  },
  created() {
    // 判断正式和开发环境
    // 场景：存在用户直接打开统一登录界面扫码登录,而不是从其他系统跳转过来的，区分环境的方式即从扫码登录的地址二级域名区别（http://localdev.auth.98du.com前缀即localdev）
    // if (!Cookies.get('nauth-env')) {
    Cookies.set('nauth-env', process.env.VUE_APP_BASE_ENV, { path: '/', domain: '.98du.com' }) // .auth.98du.com
    // }
    // this.getCaptcha()
    const handleMessage = function (event) {
      const origin = event.origin
      if( origin === 'https://login.dingtalk.com' ) { //判断是否来自ddLogin扫码事件。
        const loginTmpCode = event.data
        //获取到loginTmpCode后就可以在这里构造跳转链接进行跳转了
        window.location.href = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${APPID}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${HTTP_URL}&loginTmpCode=${loginTmpCode}`
      }
    }
    if (typeof window.addEventListener != 'undefined') {
      window.addEventListener('message', handleMessage, false)
    } else if (typeof window.attachEvent != 'undefined') {
      window.attachEvent('onmessage', handleMessage)
    }
  },
  mounted() {
    this.$nextTick(() => {
      /* eslint-disable-next-line */
      DDLogin({
        id: 'DingLogin',
        goto: HTTP_URL,
        style: 'border:none; background-color:#FFFFFF;',
        width : '350',
        height: '310'
      })

      // document.querySelector('iframe').onload = function () {
      //   console.log(this)
      //   console.log(this.contentWindow)
      // }
    })
  },
  methods: {
    clearCookies () {
      Cookies.remove('nauth-code')
      Cookies.remove('nauth-redirect')
      Cookies.remove('nauth-env')
    },
    // 登录
    handleLogin () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const { form } = this
          this.$api.loginWithPhoneNumber(form).then(res => {
            this.$message.success('登录成功！')
            const env =  Cookies.get('nauth-env')
            const domain = '.98du.com'
            const path = '/'
            const source = [
              { name: 'nauth-username', value: decodeURIComponent(res.username) },
              { name: 'nauth-userid', value: decodeURIComponent(res.userId) },
              { name: 'nauth-dduserid', value: decodeURIComponent(res.ddUserId) },
              { name: 'nauth-ddunionid', value: decodeURIComponent(res.ddUnionid) },
              { name: 'nauth-token', value: decodeURIComponent(res.token) },
            ]
            source.forEach((el) => {
              // 区分环境
              Cookies.set(`${el.name}${env === 'localdev' ? `-${env}` : ''}`, el.value, { path, domain, expires })
            })

            const url = getUrlWithParams(res)
            window.location.href = url
          })/* .catch(() => {
            this.getCaptcha()
          }) */
        }
      })
    },
    setCookies(datas) {
      const domain = '.98du.com'
      const path = '/'

      Cookies.set(`nauth-username`, datas.username, { path, domain, expires })
      Cookies.set(`nauth-token`, datas.token, { path, domain, expires })
      Cookies.set(`nauth-bearer`, datas.tokenType, { path, domain, expires })
    },
    // 获取验证码
    // getCaptcha () {
    //   getCaptcha().then(res => {
    //     this.form.encKey = res.encKey
    //     this.captchaImg = res.img
    //   })
    // },
    // 重新获取验证码
    // changeCaptchas () {
    //   this.form.captcha = ''
    //   this.getCaptcha()
    // },
    // 忘记密码
    handleForget() {
      this.$message.warning('请联系管理员重置密码！')
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
.login {

  .el-form {

    .code-img-item .el-form-item__content {
      display: flex;
    }

    .el-form-item__content {
      height: 46px;
    }

    &.password {
      .el-form-item__content {
        height: 65px;
      }
    }

    .el-input {
      height: 46px;
      border: 1px solid #e3e4e9;
      border-radius: 6px;
      overflow: hidden;
      box-sizing: border-box;

      input {
        height: 44px;
        line-height: 44px;
        background-color: #f4f5fa;
        border: 0;
      }
    }

    .forget {
      margin-top: 20px;
      // text-align: right;
      font-size: 14px;
      color: #919cb0;

      span {
        cursor: pointer;
      }
    }
  }
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
@import '~@/assets/style/variable.scss';

.login {
  position: relative;
  height: 100%;
  user-select: none;

  .login-right {
    position: absolute;
    top: 50%;
    right: 13.77vw;
    transform: translate(0, -56%);

    .login-hello {
      margin-top: 14px;
      font-size: 16px;
      text-align: center;
      color: #0e111a;
    }
  }

  .login-cover {
    width: 47.1875vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url('~@/assets/images/bg_web.png') center center no-repeat;
    background-size: cover;
  }

  .logo {
    text-align: center;
  }

  .login-box {
    position: relative;
    margin-top: 40px;
    padding: 0 50px;
    box-shadow: 1px 1px 13px 2px rgba(179, 186, 213, .38);
    border-radius: 6px;

    .switch-type {
      position: absolute;
      top: 0;
      right: 0;
      width: 78px;
      height: 64px;
      background: url('~@/assets/images/icon_qrcode.png') center center no-repeat;
      background-size: 100%;
      cursor: pointer;

      &.account {
        background: url('~@/assets/images/icon_account.png') center center no-repeat;
        background-size: 100%;
      }
    }

    .el-form {
      padding: 40px 0 60px;

      .el-form-item {
        position: relative;
        margin-bottom: 28px;

        &.code-img-item {

          .el-input {
            flex: 1;
          }

          .code-img {
            float: right;
            width: 128px;
            height: 44px;
            line-height: 44px;
            border-radius: 6px;
            margin-left: 14px;
            cursor: pointer;
            text-align: center;
            overflow: hidden;

            span {
              font-size: 14px;
              color: $default-color;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .el-input {
          height: 44px;

          input {
            background-color: #f7f4fb;
          }
        }

        .icon-container {
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          color: #c4c6cf;
          line-height: normal;
          z-index: 1;
        }

        .el-select {
          width: 100%;
        }
      }

      .form-item {
        padding-top: 18px;

        .el-button {
          width: 100%;
          height: 44px;
          border-radius: 4px;
          // background-color: #9bc9ff;
          // border-color: #9bc9ff;
        }
      }
    }
  }
}

.tab-head {
  display: flex;
  padding-top: 54px;

  .tab-title {
    position: relative;
    flex: 1;
    // height: 22px;
    // line-height: 22px;
    text-align: center;

    p {

      &:first-of-type {
        padding-top: 20px;
        font-size: 24px;
      }

      // &:last-of-type {
      //   margin-top: 16px;
      //   font-size: 13px;
      // }
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }

    span {
      display: inline-block;
      font-family: 'YaHei';
      font-size: 22px;
      color: #0e111a;
    }

    &.on {

      span {
        font-size: 22px;
        color: rgb(36, 48, 66);
        border-bottom: 1px solid #333;
      }
    }
  }
}

.ding-qrcode {
  padding-top: 10px;
  padding-bottom: 68px;
}

.login-tab {
  width: 340px;
  overflow: hidden;
}
</style>
